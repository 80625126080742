(function($) { 

    $.fn.rangeSlider = function(options) { 
        
        // Save the instance
        let instance; 

        // options override
        options = $.extend({}, $.fn.rangeSlider.defaults, options);
        
        // Set default options
        this.defaults = {
            inputs: {
                from: null,
                to: null
            },
            ionPluginOptions: {
                prettify_enabled: true,
                prettify_separator: '.',
                prefix: "&euro; ",
                force_edges: true
            },
            placeholder: null
        };

        // Initialization of plugin
        this.init = (element, options) => { 

            // Merge options 
            let ionOptions = $.extend({onFinish: this.onFinish}, this.defaults.ionPluginOptions, options.ionPluginOptions);

            // Register our custom events
            this.registerEvents();

            // Create slider
            element.ionRangeSlider(ionOptions);

            // Save our instance
            instance = element.data('ionRangeSlider');
            
            // Remove placeholder 
            this.removePlaceholder();

            // Return the instance of this plugin
            return this;
        };

        this.registerEvents = () => {

            // Event listener for the input fields
            Object.entries(options.inputs).forEach((element) => {
                element[1][0].addEventListener('change', this.updateSlider);
            });
        };

        this.onFinish = (data) => {
            this.updateInputs(data);
        };

        this.updateInputs = (data) => { 
            let element = options.inputs[instance.target][0]; // NOTE: Javascript element, not jquery
            element.value = data[instance.target];
            element.dispatchEvent(new CustomEvent('change', {bubbles: true, composed: true}));
        };

        this.updateSlider = (event) => { 
            
            // Ignore if the event was manually triggered (ergo from the slider)
            if(event.composed === true) { 
                return;
            }

            // Event listener for the input fields
            let values = [];
            Object.entries(options.inputs).forEach((element) => {
                values.push(element[1][0].value);
            });

            // Sort the array using a natural sort order
            let collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
            values.sort(collator.compare);

            // Merge values
            let newValue = values.join(';');

            // Update the slider
            $(this)[0].value = newValue;
            instance.update();
        };

        this.removePlaceholder = () => {
            if(options.placeholder !== null){ 
                options.placeholder.hide();
            }
        };

        this.init($(this), options);
    }    
}(jQuery));



// let updateToArrow = (data) => { 
            
//     const values = { 
        
//         // Arrow values are the min and max values of the "left" attribute on the ::before pseudo element of this bubble.
//         // this corrosponds to the "left" property of the .irs-from::before or the .irs-to::before.
//         'arrowMinValue': 50,
//         'arrowMaxValue': 85,

//         // Data values are the min and max values where we need to start and stop updating the pseudo element's "left" attribute.
//         // This corrosponds to the value that is selected in the range slider
//         'dataMinValue': 92,
//         'dataMaxValue': 100
//     };
    
//     // Get new arrow percentage
//     updateArrow(data.to_percent, values, '--irs-to-bubble-arrow-left');
// };

// let updateFromArrow = (data) => { 
//     const values = { 
        
//         // Arrow values are the min and max values of the "left" attribute on the ::before pseudo element of this bubble.
//         // this corrosponds to the "left" property of the .irs-from::before or the .irs-to::before.
//         'arrowMinValue': 25,
//         'arrowMaxValue': 50,

//         // Data values are the min and max values where we need to start and stop updating the pseudo element's "left" attribute.
//         // This corrosponds to the value that is selected in the range slider
//         'dataMinValue': 0,
//         'dataMaxValue': 4.5
//     };
    
//     // update the arrow
//     updateArrow(data.from_percent, values, '--irs-from-bubble-arrow-left');        
// };

// let updateArrow = (currentPercentage, values, htmlVar) => {
    
//     // Make sure we need to process
//     if(currentPercentage > values.dataMaxValue || currentPercentage < values.dataMinValue) {
//         document.documentElement.style.setProperty(htmlVar, `50%`);
//         return;
//     }
    
//     // This is the current percentage of the currently selected value
//     // But then in between the data min and max values
//     // If this number is > 100 or < 0 we don't need to listen
//     const currentPercentOfDataValues = (currentPercentage - values.dataMinValue) / (values.dataMaxValue - values.dataMinValue);


//     // Now we need to translate the previous value into a percentage for the arrow's left attribute        
//     // First we get the arrow's value range 
//     const valueRange = values.arrowMaxValue - values.arrowMinValue;

//     // Then we multiply the arrow's value range by the current data value percentage
//     const arrowPercent = valueRange * currentPercentOfDataValues;

//     // Add the min % to the newly calculated %
//     const newArrowPercentage = values.arrowMinValue + arrowPercent;

//     document.documentElement.style.setProperty(htmlVar, `${newArrowPercentage}%`);
// };